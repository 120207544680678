<template>
  <LayoutContainer ref="el" gray>
    <slot name="header" :not-found="notFound" />
    <div v-if="feed.length" class="feed-wrapper grid gap-7">
      <LayoutFeedEffect
        v-for="(items, index) in feed"
        :key="`feed-${index}`"
        class="feed"
        children-selector=".item"
      >
        <LayoutGridFeed>
          <slot name="items" :items="items" item-class="item" />
        </LayoutGridFeed>
      </LayoutFeedEffect>
    </div>
    <div v-else>
      <p class="text-center">
        <slot name="not-found">
          We appreciate you checking in. Currently, we don't have any available
          vacancies.
        </slot>
      </p>
    </div>
  </LayoutContainer>
</template>

<script setup lang="ts">
import type { LayoutContainer } from "#build/components"
import type { Feed } from "~/@types/feed"

const props = withDefaults(
  defineProps<{
    getFirstPage: () => string
    getNextPage: () => string
    groqParams?: Record<string, unknown>
  }>(),
  {
    groqParams: () => ({}),
  },
)

const emits = defineEmits<{
  "load-more": [item: Feed | undefined]
}>()

const { getFirstPage, getNextPage, groqParams } = toRefs(props)
const nextGroqParams = ref(groqParams.value)
const sanity = useSanity()
const feed = ref<Feed[][]>([])
const loadMore = ref<boolean>(true)
const el = ref<InstanceType<typeof LayoutContainer>>()

const { data: results } = await useSanityQuery<Feed[]>(
  getFirstPage.value(),
  groqParams.value,
)

const container = computed<HTMLElement | undefined>(() => el.value?.el)
const notFound = computed<boolean>(() => feed.value?.length === 0)

useFeedEffect(".feed", container)

useInfiniteScroll(
  window,
  async () => {
    results.value = await sanity
      .fetch(getNextPage.value(), nextGroqParams.value)
      .catch(() => (loadMore.value = false))
  },
  {
    distance: 500,
    canLoadMore: () => loadMore.value,
  },
)

watch(
  results,
  (newVal) => {
    if (newVal?.length) {
      emits("load-more", newVal[newVal?.length - 1])

      nextGroqParams.value = { ...groqParams.value }

      feed.value.push(newVal)
    } else {
      loadMore.value = false
    }
  },
  {
    immediate: true,
    deep: true,
  },
)
</script>
