export default (selector: string, element: Ref<HTMLElement | undefined>) => {
  const { gsap, ScrollTrigger } = useGsap()

  const context = ref<gsap.Context>()

  // const applyClass = (items: HTMLElement[], className: string) => {
  //   items.forEach((t) => t.classList.add(className))
  // }

  onMounted(() => {
    context.value = gsap.context((self) => {
      const show = (batch: gsap.TweenTarget) => {
        gsap.set(batch, { autoAlpha: 0 })
        gsap.to(batch, { autoAlpha: 1, stagger: 0.1 })
      }

      const hide = (batch: gsap.TweenTarget) =>
        gsap.set(batch, { autoAlpha: 0, overwrite: true })

      const create = (items: HTMLElement[]) => {
        // applyClass(items, "applied")

        ScrollTrigger.batch(items, {
          onEnter: show,
          onLeave: hide,
          onEnterBack: show,
          onLeaveBack: hide,
        })
      }

      if (self.selector) {
        create(self.selector(selector))
      }
    }, element.value)
  })

  onBeforeUnmount(() => {
    context.value?.revert()
  })
}
