<template>
  <div ref="el">
    <slot />
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  childrenSelector: string
}>()

const { childrenSelector } = toRefs(props)
const el = ref<HTMLElement>()

useFeedEffect(childrenSelector.value, el)
</script>
